import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  educations: [],
  certificates: [],
  expertDetail: {},
};

export const newExpertSlice = createSlice({
  name: "newExpert",
  initialState,
  reducers: {
    addEducation: (state, action) => {
      state.educations.push(action.payload);
    },
    deleteEducation: (state, action) => {
      state.educations = state.educations.filter(
        (item) => item.university + item.educationType !== action.payload
      );
    },
    addCertificates: (state, action) => {
      state.certificates.push(action.payload);
    },
    deleteCertificates: (state, action) => {
      state.certificates = state.certificates.filter(
        (item) =>
          item.certificateName + item.organizationName !== action.payload
      );
    },
    setExpertDetail: (state, action) => {
      state.expertDetail = action.payload;
    },
    setEducations: (state, action) => {
      state.educations = action.payload;
    },
    setCertificates: (state, action) => {
      state.certificates = action.payload;
    },
    resetExpertState: (state) => {
      state.expertDetail = {};
      state.certificates = [];
      state.educations = [];
    },
  },
});

export const {
  addEducation,
  deleteEducation,
  addCertificates,
  deleteCertificates,
  setExpertDetail,
  setCertificates,
  setEducations,
  resetExpertState,
} = newExpertSlice.actions;

export default newExpertSlice.reducer;
