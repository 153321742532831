import { DetailHeader } from "../../components";
import { useNavigate, useParams } from "react-router-dom";
import {
  Card,
  Input,
  Form,
  Button,
  Select,
  message,
  Tabs,
  DatePicker,
  TimePicker,
} from "antd";
import { useState } from "react";
import { PromotionCodeService } from "../../../api";
import dayjs from "dayjs";
const NewPromotionCode = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("AMOUNT_BASED");
  const navigate = useNavigate();
  const onFinish = async (values) => {
    setLoading(false);

    try {
      const date = dayjs(values.expireDate).format("YYYY-MM-DD");
      const time = dayjs(values.expireHour).format("HH:mm:ss");

      const combinedDateTime = dayjs(`${date}T${time}`).format(
        "YYYY-MM-DDTHH:mm:ss"
      );
      let body = {
        title: values.title,
        description: values.description,
        code: values.code,
        expireDate: combinedDateTime,
        availableTimezone: values.availableTimezone,
        availableCurrencyCode: values.availableCurrencyCode,
        promotionAudience: values.promotionAudience,
        promotionType: activeTab,
      };
      if (activeTab === "AMOUNT_BASED") {
        body.discountAmount = parseInt(values.discountAmount);
      } else if (activeTab === "DISCOUNT_RATE") {
        body.discountRate = parseFloat(values.discountRate);
      }
      console.log(body);
      await PromotionCodeService.createPromotion(body);
      form.resetFields();
      message.success("Başarılı");
    } catch (error) {
      message.error(JSON.stringify(error));
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <DetailHeader />
      <Form
        scrollToFirstError
        form={form}
        onFinish={onFinish}
        layout="vertical"
      >
        <Card className="mb-3" title="Bilgiler">
          <div className="row">
            <Form.Item
              className="col-12 col-md-6 col-lg-3"
              label="İndirim Kodu"
              name={"code"}
              rules={[
                {
                  required: true,
                  message: "Lütfen İndirim Kodu alanını doldurun.",
                },
              ]}
            >
              <Input name="code" size="large" placeholder="Kod " />
            </Form.Item>
          </div>
          <div className="row">
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Lütfen Başlık alanını doldurun.",
                },
              ]}
              name="title"
              className="col-12 col-md-6 col-lg-3"
              label="Başlık"
            >
              <Input size="large" name="title" placeholder="Başlık " />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Lütfen Açıklama alanını doldurun.",
                },
              ]}
              name="description"
              className="col-12 col-md-6 col-lg-3"
              label="Açıklama"
            >
              <Input.TextArea
                placeholder="Açıklama"
                name="description"
                size="large"
              />
            </Form.Item>
          </div>
        </Card>
        <Card className="mb-3" title="İndirim">
          <Tabs
            defaultActiveKey={activeTab}
            onChange={(v) => {
              form.resetFields(["discountRate", "discountAmount"]);
              setActiveTab(v);
            }}
            items={[
              {
                key: "AMOUNT_BASED",
                label: "Tutar İndirim",
                children: (
                  <Form.Item
                    label="Fiyat"
                    className="col-12 col-md-6 col-lg-3"
                    name={"discountAmount"}
                  >
                    <Input
                      type="number"
                      size="large"
                      name="discountAmount"
                      placeholder="Fiyat "
                      addonAfter={
                        <Form.Item
                          className="mb-0"
                          name="availableCurrencyCode"
                        >
                          <Select
                            size="large"
                            placeholder="Kod"
                            style={{ width: 140 }}
                            options={[
                              { value: "TRY", label: "TRY" },
                              { value: "AZN", label: "AZN" },
                            ]}
                          />
                        </Form.Item>
                      }
                    />
                  </Form.Item>
                ),
              },
              {
                key: "DISCOUNT_RATE",
                label: "Oransal İndirim",
                children: (
                  <Form.Item
                    label="Yüzdelik"
                    rules={[
                      {
                        min: 0,
                        max: 100,
                      },
                    ]}
                    className="col-12 col-md-6 col-lg-3"
                    name={"discountRate"}
                  >
                    <Input
                      step={"0.1"}
                      placeholder="Yüzdelik"
                      size="large"
                      type="number"
                      name="discountRate"
                      addonBefore="%"
                    />
                  </Form.Item>
                ),
              },
            ]}
          />
        </Card>
        <Card className="mb-3" title="Ayarlar">
          <div className="row">
            <Form.Item
              label="Saat Dilimi"
              name={"availableTimezone"}
              className="col-12 col-md-6 col-lg-3"
              rules={[
                {
                  required: true,
                  message: "Lütfen Saat Dilimi alanını doldurun.",
                },
              ]}
            >
              <Select
                showSearch
                optionFilterProp="value"
                name={"availableTimezone"}
                allowClear
                style={{
                  width: "100%",
                }}
                placeholder="Saat Dilimi Seçiniz"
                options={[
                  { value: "Europe/Istanbul", label: "Europe/Istanbul" },
                  { value: "Asia/Baku", label: "Asia/Baku" },
                ]}
                size="large"
              />
            </Form.Item>
          </div>
          <div className="row ">
            <Form.Item
              label="Son Kullanım Tarihi"
              className="col-6 col-md-6 col-lg-3"
              name={"expireDate"}
              rules={[
                {
                  required: true,
                  message: "Lütfen Son Kullanım Tarihi alanını doldurun.",
                },
              ]}
            >
              <DatePicker
                size="large"
                format={"DD-MM-YYYY"}
                name={"expireDate"}
                style={{
                  width: "100%",
                }}
                placeholder={"Son Kullanım Tarihini Seçiniz"}
              />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Lütfen Son Kullanım Saati alanını doldurun.",
                },
              ]}
              className="col-6 col-md-6 col-lg-3"
              label="Son Kullanım Saati"
              name={"expireHour"}
            >
              <TimePicker
                size="large"
                format={"HH:mm:ss"}
                placeholder="Son Kullanım Saati Seçiniz"
                name="expireHour"
              />
            </Form.Item>
          </div>
          <div className="row">
            <Form.Item
              label="Promosyon Hedef Kitlesi"
              name={"promotionAudience"}
              className="col-12 col-md-6 col-lg-3"
              rules={[
                {
                  required: true,
                  message: "Lütfen Promosyon Hedef Kitlesi alanını doldurun.",
                },
              ]}
            >
              <Select
                showSearch
                optionFilterProp="value"
                name={"promotionAudience"}
                allowClear
                style={{
                  width: "100%",
                }}
                placeholder="Promosyon Hedef Kitlesi Seçiniz"
                options={[
                  { value: "ALL_USERS", label: "Bütün Kullanıcılar" },
                  { value: "NEW_USERS", label: "Yeni Kullanıcılar" },
                ]}
                size="large"
              />
            </Form.Item>
          </div>
        </Card>
        <Form.Item label="">
          <Button
            loading={loading}
            size="large"
            className="w-100 mt-3"
            htmlType="submit"
            type="primary"
          >
            Ekle
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
export default NewPromotionCode;
