import React from "react";
import { useNavigate } from "react-router-dom";

//Table
import AgGridTable from "../../components/AgGridTable";

// Expert Service
import { CategoryService } from "../../../api";
import { Button, message } from "antd";

import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";

import useQuery from "../../hooks/useQuery";
import { categoriesListColumn } from "../../components/Columns";

const Category = () => {
  const { queryLoading, queryData } = useQuery({
    service: CategoryService.getCategories,
  });
  const navigate = useNavigate();

  const onRowClick = (params) => {
    // const pushUrl = "/expert-detail/" + params.data.expertId;
    // navigate(pushUrl);
  };
  const deleteCategory = async (id) => {
    try {
      await CategoryService.deleteCategory(id);
      message.success("Başarılı");
      window.location.reload();
    } catch (error) {
      message.error("Hata");
    } finally {
    }
  };
  return (
    <AgGridTable
      loading={queryLoading}
      onRowClicked={onRowClick}
      rowData={queryData?.contentCategories}
      columnDefs={[
        ...categoriesListColumn,
        {
          headerName: "Sil",
          maxWidth: 200,
          cellRenderer: (params) => {
            return (
              <Button
                type="dashed"
                onClick={() => deleteCategory(params.data.categoryId)}
              >
                <DeleteOutlined />
                Sil
              </Button>
            );
          },
        },
      ]}
      sideBar={"filters"}
      headerChildren={
        <Button
          onClick={() => {
            navigate("/new-category");
          }}
          type="primary"
        >
          <PlusOutlined />
          Yeni Kategori Ekle
        </Button>
      }
    />
  );
};

export default Category;
