import { useSelector } from "react-redux";

import { Card, Form, TimePicker, Switch } from "antd";
import dayjs from "dayjs";

const SeansSettins = () => {
  const expertDetail = useSelector((state) => state.newExpert.expertDetail);
  return (
    <Card title="Seans Ayarları" className="mb-3">
      <div className="row">
        <Form.Item
          className="col-12 col-md-6 col-lg-3"
          label="Seans Süresi(dakika)"
          name={"overallServiceMinutes"}
        >
          <TimePicker
            size="large"
            defaultValue={dayjs(
              expertDetail?.overallServiceMinutes?.toString() || "00",
              "mm"
            )}
            format={"mm"}
            placeholder="Seans Süresi Seçiniz"
            name="overallServiceMinutes"
            className="w-100"
          />
        </Form.Item>
        <Form.Item
          label="Çift Terapistliği Kabul Et"
          name={"acceptCoupleTherapy"}
          initialValue={expertDetail.acceptCoupleTherapy}
          valuePropName="checked"
          className="col-12 col-md-6 col-lg-3"
        >
          <Switch
            size="large"
            defaultValue={expertDetail.acceptCoupleTherapy}
            name={"acceptCoupleTherapy"}
          />
        </Form.Item>
      </div>
    </Card>
  );
};

export default SeansSettins;
