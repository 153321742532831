import React, { useState } from "react";
import DetailHeader from "../../components/DetailHeader";
import {
  Affix,
  Form,
  Button,
  message,
  Card,
  Input,
  ColorPicker,
  Switch,
} from "antd";
import { CategoryService } from "../../../api";
const NewCategory = () => {
  const [loadingBtn, setLoadingBtn] = useState(false);

  const [form] = Form.useForm();
  const onFinish = async (values) => {
    try {
      values.sortOrder = parseInt(values.sortOrder);
      values.valueTuple = {
        TR: values.titleTr,
        EN: values.titleEn,
      };
      values.descriptionTuple = {
        TR: values.descriptionTr,
        EN: values.descriptionEn,
      };
      const response = await CategoryService.createCategory(values);
      message.success("İşlem Başarılı!");
      form.resetFields();
    } catch (error) {
      message.error(JSON.stringify(error?.msgDetail || error));
    } finally {
      setLoadingBtn(false);
    }
  };
  return (
    <>
      <DetailHeader />
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        autoComplete="off"
        scrollToFirstError
        style={{ marginBottom: 50 }}
      >
        <Card className="mb-3 " title="Detay Bilgiler">
          <div className="row">
            <Form.Item
              label="Başlık Türkçe"
              rules={[
                { required: true, message: "Lütfen Başlık alanını doldurun." },
              ]}
              className="col-12 col-md-6 col-lg-6"
              name={"titleTr"}
            >
              <Input
                placeholder="Başlık Yazınız"
                size="large"
                name="titleTr"
                addonBefore="TR"
              />
            </Form.Item>
            <Form.Item
              label="Başlık İngilizce"
              rules={[
                { required: true, message: "Lütfen Başlık alanını doldurun." },
              ]}
              className="col-12 col-md-6 col-lg-6"
              name={"titleEn"}
            >
              <Input
                placeholder="Başlık Yazınız"
                size="large"
                name="titleEn"
                addonBefore="EN"
              />
            </Form.Item>
          </div>
        </Card>
        <Card className="mb-3 " title="Mobile Ayarlar">
          <div className="row">
            <Form.Item
              label="1. Renk"
              className="col-12 col-md-4 col-lg-4"
              name={"primaryColorCode"}
              rules={[
                { required: true, message: "Lütfen Renk alanını doldurun." },
              ]}
              getValueFromEvent={(color) => {
                return "#" + color.toHex();
              }}
            >
              <ColorPicker size="large" showText />
            </Form.Item>
            <Form.Item
              label="2. Renk"
              className="col-12 col-md-4 col-lg-4"
              name={"secondaryColorCode"}
              rules={[
                { required: true, message: "Lütfen Renk alanını doldurun." },
              ]}
              getValueFromEvent={(color) => {
                return "#" + color.toHex();
              }}
            >
              <ColorPicker size="large" showText />
            </Form.Item>
            <Form.Item
              label="3. Renk"
              className="col-12 col-md-4 col-lg-4"
              name={"tertiaryColorCode"}
              rules={[
                { required: true, message: "Lütfen Renk alanını doldurun." },
              ]}
              getValueFromEvent={(color) => {
                return "#" + color.toHex();
              }}
            >
              <ColorPicker size="large" showText />
            </Form.Item>
          </div>
          <div className="row">
            <Form.Item
              label="Sıralama"
              className="col-12 col-md-4 col-lg-4"
              name={"sortOrder"}
              rules={[
                {
                  required: true,
                  message: "Lütfen Sıralama alanını doldurun.",
                },
              ]}
            >
              <Input
                placeholder="Sıralama Yazınız"
                size="large"
                name="sortOrder"
                type="number"
              />
            </Form.Item>
          </div>
          <div className="row">
            <Form.Item
              label="Kullanıcı önerisi"
              name={"featured"}
              valuePropName="checked"
              className="col-12 col-md-6 col-lg-3"
              initialValue={false}
            >
              <Switch
                defaultValue={false}
                value={false}
                size="large"
                name={"featured"}
              />
            </Form.Item>
          </div>
          <div className="row">
            <Form.Item
              label="TR Açıklama"
              className="col-12 col-md-4 col-lg-4"
              name={"descriptionTr"}
              rules={[
                {
                  required: true,
                  message: "Lütfen Açıklama alanını doldurun.",
                },
              ]}
            >
              <Input.TextArea
                placeholder="Türkçe Açıklama Yazınız"
                size="large"
                name="descriptionTr"
              />
            </Form.Item>
            <Form.Item
              label="EN Açıklama"
              className="col-12 col-md-4 col-lg-4"
              name={"descriptionEn"}
              rules={[
                {
                  required: true,
                  message: "Lütfen Açıklama alanını doldurun.",
                },
              ]}
            >
              <Input.TextArea
                placeholder="İngilizce Açıklama Yazınız"
                size="large"
                name="descriptionEn"
              />
            </Form.Item>
          </div>
        </Card>

        <Affix offsetBottom={0}>
          <Form.Item>
            <Button
              loading={loadingBtn}
              size="large"
              className="w-100"
              htmlType="submit"
              type="primary"
              style={{ marginBottom: 50 }}
            >
              {"Ekle"}
            </Button>
          </Form.Item>
        </Affix>
      </Form>
    </>
  );
};
export default NewCategory;
