import { useState } from "react";
import { Button, Card, Modal, Form, Input, DatePicker, Popconfirm } from "antd";
import AgGridTable from "../../AgGridTable";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import {
  addCertificates,
  deleteCertificates,
} from "../../../redux/newExpertSlice";
import dayjs from "dayjs";

const CertificateItem = () => {
  const format = "YYYY";
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const certificates = useSelector((state) => state.newExpert.certificates);
  const [forms] = Form.useForm();
  const [columnDefs, _] = useState([
    { field: "certificateName", headerName: "Sertifika Adı", minWidth: 250 },
    {
      field: "organizationName",
      headerName: "Organizasyon Adı",
      minWidth: 250,
    },
    { field: "trainingName", headerName: "Eğitim Adı", minWidth: 250 },
    {
      field: "certificateDate",
      headerName: "Sertifika Tarihleri",
      minWidth: 250,
    },
    {
      field: "",
      headerName: "Sil",
      cellRenderer: (params) => {
        return (
          <Popconfirm
            okText="Evet"
            placement="top"
            title="Uyarı"
            description="Silmek istediğinize emin misiniz?"
            onConfirm={() => {
              dispatch(
                deleteCertificates(
                  params.data.certificateName + params.data.organizationName
                )
              );
            }}
          >
            <Button className="text-red" type="text" size="small">
              <CloseOutlined />
            </Button>
          </Popconfirm>
        );
      },
    },
  ]);
  const onFinish = (values) => {
    const dates = {
      startDate: dayjs(values.year[0]).format(format),
      endDate: dayjs(values.year[1]).format(format),
    };
    delete values.year;
    dispatch(
      addCertificates({
        ...values,
        certificateDate: `${dates.startDate}-${dates.endDate}`,
      })
    );
    setIsModalOpen(false);
  };
  return (
    <Card
      className="mb-3"
      title="Sertifika Listesi"
      extra={
        <Button type="primary" onClick={() => setIsModalOpen(true)}>
          <PlusOutlined />
          Yeni Ekle
        </Button>
      }
    >
      <AgGridTable
        pagination={false}
        noRowsOverlayComponent={() => "Veri bulunamadı."}
        rowData={certificates}
        columnDefs={columnDefs}
        height={300}
        closeHeader
        defaultColDef={{
          filter: false,
        }}
      />
      <Modal
        title="Yeni Sertifika Ekle"
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        width={600}
        centered
        cancelText="İptal"
        okText="Ekle"
        footer={false}
      >
        <Card>
          <Form
            layout="vertical"
            autoComplete="off"
            forms={forms}
            onFinish={onFinish}
            scrollToFirstError
          >
            <Form.Item
              rules={[{ required: true, message: "Boş bırakılamaz." }]}
              label="Sertifika Adı"
              name="certificateName"
            >
              <Input
                name="certificateName"
                size="large"
                placeholder="Sertifika Adı Yazınız"
              />
            </Form.Item>
            <Form.Item
              rules={[{ required: true, message: "Boş bırakılamaz." }]}
              label="Eğitim Adı"
              name="trainingName"
            >
              <Input
                name="trainingName"
                size="large"
                placeholder="Eğitim Adı Yazınız"
              />
            </Form.Item>
            <Form.Item
              rules={[{ required: true, message: "Boş bırakılamaz." }]}
              label="Organizasyon Adı"
              name="organizationName"
            >
              <Input
                name="organizationName"
                size="large"
                placeholder="Organizasyon Adı Yazınız"
              />
            </Form.Item>
            <Form.Item
              rules={[{ required: true, message: "Boş bırakılamaz." }]}
              label="Eğitim Tarihleri"
              name="year"
            >
              <DatePicker.RangePicker
                format={format}
                name={["startDate", "endDate"]}
                className="w-100"
                size="large"
                placeholder={["Başlangıç", "Bitiş"]}
                picker="year"
              />
            </Form.Item>
            <Form.Item key="submit">
              <Button
                size="large"
                className="w-100"
                htmlType="submit"
                type="primary"
              >
                Ekle
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Modal>
    </Card>
  );
};
export default CertificateItem;
